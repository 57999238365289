body,
html {
  padding: 0;
  margin: 0;
}

html {
  overflow-y: overlay;
  overflow-x: hidden;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    border-left: 1px solid #fafafa;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(40, 88, 123, 0.5);
    border-radius: 5px;
    border-left: 1px solid #fafafa;
  }
}

body {
  background: linear-gradient(180deg, #f9fafb 0%, #e8e8e8 100%), #f9fafb;
  color: #252626;
  transition: 0.3s;

  &.darkTheme {
    background: #1a1f2c;
    color: #dadada;

    &::-webkit-scrollbar {
      width: 8px;
      border-left: 1px solid;
      border-image: linear-gradient(180deg, #1e2023 0%, #303338 100%) 1;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(40, 88, 123, 0.5);
      border-radius: 5px;
      border-left: 1px solid;
      border-image: linear-gradient(180deg, #1e2023 0%, #303338 100%) 1;
    }
  }

  ol {
    & li {
      font-size: 18px;
      margin-bottom: 0.5rem;
    }
  }
}

.Toastify__toast-container {
  width: unset !important;
  cursor: default !important;
}

.Toastify__toast {
  background: unset;
  background-color: unset !important;
  box-shadow: unset !important;
  overflow: unset !important;
  cursor: default !important;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: bold;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 700;
  font-style: bolder;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato/Lato-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato/Lato-Semibold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Sarabun  is being used in landing and article pages
@font-face {
  font-family: 'Sarabun-Regular';
  src: url('/fonts/Sarabun/Sarabun-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sarabun-Medium';
  src: url('/fonts/Sarabun/Sarabun-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sarabun-Bold';
  src: url('/fonts/Sarabun/Sarabun-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DMSerifDisplay-Regular';
  src: url('/fonts/DMSerifDisplay-Regular.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('/fonts/biases/IBMPlexMono-Regular.ttf') format('truetype');
  font-weight: normal;
}

// Oswald is being used in BadgeBox aka Achievement Box
@font-face {
  font-family: 'Oswald';
  src: url('/fonts/biases/Oswald-Bold') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'RedHatDisplay';
  src: url('/fonts/biases/RedHatDisplay.ttf');
  font-display: swap;
}

// Aldrich is being used in Company Management(new)
@font-face {
  font-family: 'Aldrich';
  src: url('/fonts/Aldrich-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Tomorrow-Regular';
  src: url('/fonts/Tomorrow/Tomorrow-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// This is only for mobile
@font-face {
  font-family: 'Tomorrow';
  src: url('/fonts/Tomorrow/Tomorrow-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Tomorrow mediumru
@font-face {
  font-family: 'Tomorrow-Medium';
  src: url('/fonts/Tomorrow/Tomorrow-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Jost - for Russian company management

@font-face {
  font-family: 'Jost-Regular';
  src: url('/fonts/Jost/Jost-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost-Medium';
  src: url('/fonts/Jost/Jost-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// For user information of uxcat. Ranking and Top
@font-face {
  font-family: 'DelaGothicOne-Regular';
  src: url('/fonts/DelaGothicOne-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// For UXCat certificate
@font-face {
  font-family: 'Manrope-ExtraLight.ttf';
  src: url('/fonts/Manrope-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

// For UXCat certificate
@font-face {
  font-family: 'IBMPlexSans-Regular.ttf';
  src: url('/fonts/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// For UXCat certificate
@font-face {
  font-family: 'IBMPlexSans-SemiBold.ttf';
  src: url('/fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Lato', Arial, serif;
}

@media (max-width: 961px) {
  body {
    background-size: contain;
  }
}
